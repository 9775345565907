import { createRouter, createWebHistory } from "vue-router"
import StartPage from '@/components/pages/StartPage'
import PlayersPage from '@/components/pages/PlayersPage'
import GamePage from '@/components/pages/GamePage'
import ResultsPage from '@/components/pages/ResultsPage'
import FormPage from '@/components/pages/FormPage'
import ContactPage from '@/components/pages/ContactPage'
import MapPage from '@/components/pages/MapPage'
import InfoPage from '@/components/pages/InfoPage'
import GiftPage from '@/components/pages/GiftPage'
import Wrapper from "@/components/pages/Wrapper";


const routeInfos  = [
  {
    path: '/:ogrod([a-zA-Z0-9\\-]+)',
    name: 'main-router',
    component: Wrapper,
    props:true,
    children: [
      {
        path: ':pathMatch(.*)*',
        name: 'start-pages',
        redirect: { name: "start-page" }

      },
      {
        path: '',
        name: 'start-pages-empty',
        redirect: { name: "start-page" }

      },
      {
        path: 'start',
        name: 'start-page',
        component: StartPage,
      },
      {
        path: 'players',
        name: 'players-page',
        component: PlayersPage,
      },
      {
        path: 'game',
        name: 'game-page',
        component: GamePage,
      },
      {
        path: 'results',
        name: 'results-page',
        component: ResultsPage,
      },
      {
        path: 'form',
        name: 'form-page',
        component: FormPage,
      },
      {
        path: 'contact',
        name: 'contact-page',
        component: ContactPage,
      },
      {
        path: 'map',
        name: 'map-page',
        component: MapPage,
      },
      {
        path: 'info',
        name: 'info-page',
        component: InfoPage,
      },
      {
        path: 'gift',
        name: 'gift-page',
        component: GiftPage,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'red-preg',
    beforeEnter() {
      window.location = 'https://gardenoflights.com/en/404'
    }
  },
  {
    path: '',
    name: 'red-preg-empty',
    beforeEnter() {
      window.location = 'https://gardenoflights.com/en/404'
    }
  }
];

let router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history : createWebHistory(),
  routes : routeInfos
});

// router.beforeEach((to, from, next) => {
//   if ( to.params.hasOwnProperty('ogrod') && to.params.ogrod.length <1 ) {
//     window.location = 'https://gardenoflights.com/'
//  }
// });

export default router
