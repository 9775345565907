<template>
  <div class="w-100 d-flex flex-column">
    <header-bar></header-bar>
    <div class="flex-grow h-100">
      <div id="carouselConteiner" class="carousel h-100" data-bs-wrap="false" data-bs-interval="false" data-ride="carousel">
        <div class="carousel-inner h-100">
          <div id="first-slide" class="carousel-item carousel-item-1 active">
            <div class="text-block">{{text1}} <img :src="require('@/assets/images/Tablica.png')" class="point"> .</div>
            <div class="text-block">{{text2}}</div>
            <img :src="require('@/assets/images/slider1.png')" class="slider1">
          </div>
          <div class="carousel-item carousel-item-2" >
            <img :src="slider2" class="slider2">
            <div class="text-block">{{text3}}</div>
          </div>
          <div class="carousel-item carousel-item-3">
            <div class="text-block">{{text4}}</div>
            <img :src="slider3" class="slider3">
          </div>
          <div  id="last-slide" class="carousel-item carousel-item-4" >
            <div class="text-block">{{text5}}</div>
            <div class="buttons-line"><button type="button" class="btn btn-primary" @click="start()">{{startButtonText}}</button></div>
            <img :src="require('@/assets/images/slider4.png')" class="slider4">
          </div>
        </div>
        <button v-if="!hidePrev" class="carousel-control-prev" type="button" data-bs-target="#carouselConteiner" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button v-if="!hideNext" class="carousel-control-next" type="button" data-bs-target="#carouselConteiner" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselConteiner" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselConteiner" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselConteiner" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselConteiner" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from '../../store/store.js'

export default {
  components: {HeaderBar},
  data() {
    return {
      store: store,
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      startButtonText: '',
      slider2: '',
      slider3: '',
      hidePrev: false,
      hideNext: false,
    }
  },
  mounted() {
    store.phoneButton = false;
    store.mapButton = false;
    store.infoButton = false;
    store.showBackButton = false;
    store.logoSmall = false;
    this.getVariables();
    this.calculateButtons();
    var myCarousel = document.getElementById('carouselConteiner')
    myCarousel.addEventListener('slid.bs.carousel', this.calculateButtons)
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.startButtonText = storage.interface.startButtonText;
      this.text1 = storage.pages.start.text1;
      this.text2 = storage.pages.start.text2;
      this.text3 = storage.pages.start.text3;
      this.text4 = storage.pages.start.text4;
      this.text5 = storage.pages.start.text5;
      this.slider2 = storage.pages.start.slider2;
      this.slider3 = storage.pages.start.slider3;
    },
    calculateButtons() {
      this.hidePrev = this.isFirstSlideActive();
      this.hideNext = this.isLastSlideActive();
    },
    isFirstSlideActive() {
      let elem = document.getElementById('first-slide');
      return elem && elem.classList.contains('active');
    },
    isLastSlideActive() {
      let elem = document.getElementById('last-slide');
      return elem && elem.classList.contains('active');
    },
    start(){
      this.store.player1InputVisible = true,
      this.store.player2InputVisible = false,
      this.store.player3InputVisible = false,
      this.store.player4InputVisible = false,
      this.store.players = ['', '', '', ''];
      this.store.gameDuration = 0;
      this.store.gameStartTime = null;
      this.store.formHasBeenSent = false;
      this.store.playersAnswers = {
        "1": {},
        "2": {},
        "3": {},
        "4": {}
      };
      this.store.playersScores = {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0
      };
      this.store.playersResults = {
        "1": '',
        "2": '',
        "3": '',
        "4": ''
      };
      this.$router.push('players');
    }
  },
}

</script>

<style scoped>
.carousel-item{
  position:relative;
  top:50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.carousel-item-1 .text-block{
  margin: 0px auto 20px auto;
}
.carousel-item-2 .text-block{
  margin: 60px auto 0px auto;
}
.carousel-item-3 .text-block{
  margin: 0px auto 20px auto;
}
.carousel-item-4 .text-block{
  margin: 0px auto 40px auto;
}
.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.carousel-indicators{
  bottom: -30px;
}
.carousel-item-1, .carousel-item-3{
  background-image: url('@/assets/images/Flare-2.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 120%;
  margin-top: 20px;
}
.carousel-item-2{
  background-image: url('@/assets/images/Flare-3.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 140%;
}
.carousel-item-4{
  background-image: url('@/assets/images/Flare-3.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 110%;
}
img.slider1, img.slider4{
  width: 220px;
}
img.slider2, img.slider3{
  width: 260px;
}
img.point{
  display: inline;
  height: 25px;
  width: auto;
}
.buttons-line{
  display: block;
  margin: 0px auto 20px 0px;
}
</style>
