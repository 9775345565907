import {reactive, watch} from 'vue'

let storeJson = JSON.parse(localStorage.getItem('ogrod-store'));
if (!storeJson) {
    let defaultLang = '';

    storeJson = {
        language: defaultLang,
        languageName: defaultLang.toUpperCase(),
        availableLanguages: ['en'],
        languagesConfig: {
            en: {name: 'EN'}
        },
        phoneButton: false,
        mapButton: false,
        infoButton: false,
        showBackButton: false,
        logoSmall: false,
        players: ['', '', '', ''],
        playersAnswers: {
            "1": {},
            "2": {},
            "3": {},
            "4": {}
        },
        player1InputVisible: true,
        player2InputVisible: false,
        player3InputVisible: false,
        player4InputVisible: false,
        formHasBeenSent: false,
        gameStartTime: '',
        gameDuration: '',
        playersScores: {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0
        },
        playersResults: {
            "1": '',
            "2": '',
            "3": '',
            "4": ''
        },
    };
}

const store = reactive(storeJson);

watch(
    () => store,
    (val) => {
        localStorage.setItem('ogrod-store', JSON.stringify(val))
    },
    {deep: true}
)

export default store
