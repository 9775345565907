<template>
  <tr>
    <td class="number">{{question}}</td>
    <td class="question">{{questions[question]}}</td>
    <td class="answer" :id="'answer-' + question + 'A'" @click="selectAnswer(player, question,'A');"></td>
    <td class="answer" :id="'answer-' + question + 'B'" @click="selectAnswer(player, question,'B');"></td>
    <td class="answer" :id="'answer-' + question + 'C'" @click="selectAnswer(player, question,'C');"></td>
  </tr>
</template>

<script>
import store from '../../store/store.js'

export default {
  name: "GameRow",
  props: ['question','player'],
  data() {
    return {
      store: store,
      questions: '',
      answers: '',
    }
  },
  mounted() {
    this.getVariables();
    this.showAnswers();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      document.getElementById("footer").style.backgroundImage =   "url('" + storage.footer.bgimage + "')";
      this.questions = storage.pages.game.questions;
      this.answers = storage.pages.game.answers;
    },
    selectAnswer(player, question, answer){
      if (!this.store.playersAnswers[player][question]) {
        this.store.playersAnswers[player][question] = [];
      }
      if( this.store.playersAnswers[player][question].indexOf(answer) === -1){
        this.store.playersAnswers[player][question].push(answer);
      }

      if(this.answers[question]['column'] == answer) {
        document.getElementById("answer-" + question + answer).classList.add("answer-ok");
        document.getElementById("answer-" + question + answer).innerHTML = this.answers[question]['letter'];
      }
      else{
        document.getElementById("answer-" + question + answer).classList.add("answer-wrong");
      }
    },
    showAnswers(){
      if (this.store.playersAnswers && this.store.playersAnswers[this.$props.player]) {
        let questions = this.store.playersAnswers[this.$props.player];
        if (questions && questions[this.question]) {
            questions[this.question].forEach((answer) => {
              this.selectAnswer(this.$props.player, this.question, answer)
            });
        }
      }
    }
  },
}
</script>

<style scoped>

table{
  margin: 10px auto;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.number{
  width: 30px;
  height: 30px;
  background-image: url('@/assets/images/Tablica.png');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
  color: #000000;
  font-size: 10pt;
  font-weight: 700;
  font-style: normal;
  vertical-align: middle;
}
.question{
  font-size: 8pt;
  line-height: 10pt;
  text-align: left;
  padding-left:5px;
  width: auto;
}
.answears-head{
  width: 33px;
  height: 25px;
}
.answears-head button{
  width: 33px;
  height: 25px;
  padding: 0px;
}
</style>
