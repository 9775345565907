<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center" id="game">
      <h1>{{pageTitle}}</h1>
      <div class="playDuration">{{gameDuration}}: {{ minutesVar }} {{minutesText}} {{ secondsVar }} {{secondsText}}</div>

      <div class="playerName" v-if='store.players[0]'>{{store.players[0]}} <span id="score-1"></span></div>
      <table v-if='store.players[0]'>
        <tr>
          <td class="answer answer-wrong" id="answer-1-1"></td>
          <td class="answer answer-wrong" id="answer-1-2"></td>
          <td class="answer answer-wrong" id="answer-1-3"></td>
          <td class="answer answer-wrong" id="answer-1-4"></td>
          <td class="answer answer-wrong" id="answer-1-5"></td>
          <td class="answer answer-wrong" id="answer-1-6"></td>
          <td class="answer answer-wrong" id="answer-1-7"></td>
          <td class="answer answer-wrong" id="answer-1-8"></td>
          <td class="answer answer-wrong" id="answer-1-9"></td>
          <td class="answer answer-wrong" id="answer-1-10"></td>
        </tr>
      </table>
      <div class="playerName" v-if='store.players[1]'>{{store.players[1]}} <span id="score-2"></span></div>
      <table v-if='store.players[1]'>
        <tr>
          <td class="answer answer-wrong" id="answer-2-1"></td>
          <td class="answer answer-wrong" id="answer-2-2"></td>
          <td class="answer answer-wrong" id="answer-2-3"></td>
          <td class="answer answer-wrong" id="answer-2-4"></td>
          <td class="answer answer-wrong" id="answer-2-5"></td>
          <td class="answer answer-wrong" id="answer-2-6"></td>
          <td class="answer answer-wrong" id="answer-2-7"></td>
          <td class="answer answer-wrong" id="answer-2-8"></td>
          <td class="answer answer-wrong" id="answer-2-9"></td>
          <td class="answer answer-wrong" id="answer-2-10"></td>
        </tr>
      </table>
      <div class="playerName" v-if='store.players[2]'>{{store.players[2]}} <span id="score-3"></span></div>
      <table v-if='store.players[2]'>
        <tr>
          <td class="answer answer-wrong" id="answer-3-1"></td>
          <td class="answer answer-wrong" id="answer-3-2"></td>
          <td class="answer answer-wrong" id="answer-3-3"></td>
          <td class="answer answer-wrong" id="answer-3-4"></td>
          <td class="answer answer-wrong" id="answer-3-5"></td>
          <td class="answer answer-wrong" id="answer-3-6"></td>
          <td class="answer answer-wrong" id="answer-3-7"></td>
          <td class="answer answer-wrong" id="answer-3-8"></td>
          <td class="answer answer-wrong" id="answer-3-9"></td>
          <td class="answer answer-wrong" id="answer-3-10"></td>
        </tr>
      </table>
      <div class="playerName" v-if='store.players[3]'>{{store.players[3]}} <span id="score-4"></span></div>
      <table v-if='store.players[3]'>
        <tr>
          <td class="answer answer-wrong" id="answer-4-1"></td>
          <td class="answer answer-wrong" id="answer-4-2"></td>
          <td class="answer answer-wrong" id="answer-4-3"></td>
          <td class="answer answer-wrong" id="answer-4-4"></td>
          <td class="answer answer-wrong" id="answer-4-5"></td>
          <td class="answer answer-wrong" id="answer-4-6"></td>
          <td class="answer answer-wrong" id="answer-4-7"></td>
          <td class="answer answer-wrong" id="answer-4-8"></td>
          <td class="answer answer-wrong" id="answer-4-9"></td>
          <td class="answer answer-wrong" id="answer-4-10"></td>
        </tr>
      </table>

      <div class="buttons-line"><button type="button" class="btn btn-primary" id="giftButton" @click.stop="store.formHasBeenSent ? $router.push('gift'):$router.push('form')">{{buttonText}}</button></div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from "../../store/store";

export default {
  components: {HeaderBar},
  name: "ResultsPage",
  data() {
    return {
      store: store,
      pageTitle: '',
      buttonText: '',
      gameDuration: '',
      minutesText: '',
      secondsText: '',
      minutesVar: '',
      secondsVar: '',
      answers: '',
    }
  },
  mounted() {
    this.getVariables();
    store.phoneButton = true;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = true;
    store.logoSmall = true;
    this.showAnswers();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.gameDuration = storage.interface.gameDuration;
      this.minutesText = storage.interface.minutesText;
      this.secondsText = storage.interface.secondsText;
      this.pageTitle = storage.pages.results.h1;
      this.buttonText = storage.pages.results.buttonText;
      this.answers = storage.pages.game.answers;

      this.minutesVar = Math.floor((this.store.gameDuration/1000/60) << 0);
      this.secondsVar = Math.floor((this.store.gameDuration/1000) % 60);
    },
    showAnswers(){
        store.players.forEach((element,index) => {
          if(element!=''){
            let player = index + 1;
            let score = 0;
            let result = '';
            for (let question = 1; question <= 10 ; question++) {
              if(this.store.playersAnswers[player][question] && this.store.playersAnswers[player][question].includes(this.answers[question]['column'])){
                score++;
                document.getElementById("answer-" + player + "-" + question).classList.remove("answer-wrong");
                document.getElementById("answer-" + player + "-" + question).classList.add("answer-ok");
                document.getElementById("answer-" + player + "-" + question).innerHTML = this.answers[question]['letter'];
                document.getElementById("score-" + player).innerHTML = "(" + score + "/10)";
                result = result + this.answers[question]['letter'];
              }else{
                result  = result + '?';
              }
            }
            //save to store
            this.store.playersScores[player] = score;
            this.store.playersResults[player] = result;
          }
        });
    }
  },
}
</script>

<style scoped>
#game{
  width: 80%;
  padding: 30px 0px;
}
h1{
  font-size: 15pt;
  margin: 0px;
}
table{
  margin: 0px;
}
.playerName{
  font-size: 11pt;
  text-align: left;
  margin-top: 20px;
}
#giftButton{
  margin-top: 30px;
}
.playDuration{
  font-size: 11pt;
}
</style>
