<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center" id="game">
      <h1>{{pageTitle}}</h1>
      <div class="fillFormText">{{fillFormText}}</div>
      <div class="mandatoryFieldsText">{{mandatoryFieldsText}}</div>
      <hr/>
      <form>
        <div class="form-group">
          <label  for="nameInput" class="float-start">{{nameLabel}}*</label>
          <input v-model="name"  type="text" id="nameInput" maxlength="50" tabindex="1"
                 v-bind:class="{'form-control':true, 'is-invalid' : !validName(name) && nameBlured}" v-on:blur="nameBlured=true">
          <div class="invalid-feedback">{{mandatoryFieldText}}</div>
        </div>
        <div class="form-group">
          <label for="emailInput" class="float-start">{{emailLabel}}*</label>
          <input v-model="email" type="email" id="emailInput"  maxlength="50" tabindex="2"
                 v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured=true">
          <div class="invalid-feedback">{{mandatoryFieldText}}</div>
        </div>
        <div class="form-group">
          <label for="phoneInput" class="float-start">{{phoneLabel}}</label>
          <input v-model="phone" type="text" class="form-control" id="phoneInput" tabindex="3"
                 v-bind:class="{'form-control':true, 'is-invalid' : !validPhone(phone) && phoneBlured}" v-on:blur="phoneBlured=true">
          <div class="invalid-feedback">{{mandatoryFieldText}}</div>
        </div>
        <div class="form-check">
          <input v-model="consent1" type="checkbox" id="consent1Check" tabindex="4"
                 v-bind:class="{'form-check-input':true, 'is-invalid' : !validConsent1() && consent1Blured}" v-on:input="consent1Blured=true">
          <label class="form-check-label float-start consent" for="consent1Check" v-html="consent1Text"></label>
        </div>
      </form>
      <div class="buttons-line"><button type="submit" :disabled="!validName(name) || !validEmail(email) || !validPhone(phone) || !validConsent1()"  v-on:click.stop.prevent="submit" class="btn btn-primary" id="giftButton">{{buttonText}}</button></div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from "../../store/store";
import http from "@/axios";

export default {
  components: {HeaderBar},
  name: "FormPage",
  data() {
    return {
      store: store,
      pageTitle: '',
      buttonText: '',
      fillFormText: '',
      nameLabel: '',
      emailLabel: '',
      phoneLabel: '',
      consent1Text: '',
      mandatoryFieldText: '',
      mandatoryFieldsText: '',
      mandatoryConsentText: '',

      name : "",
      email : "",
      phone : "",
      consent1 : false,
      nameBlured : false,
      emailBlured : false,
      phoneBlured : false,
      consent1Blured : false,
      valid : false,
      submitted : false,
      gameDuration : '',
      playersAnswers : '',
      players : '',
      gardenId : '',
      language : '',
      playersScores : '',
      playersResults : '',
    }
  },
  mounted() {
    this.getVariables();
    store.phoneButton = true;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = true;
    store.logoSmall = true;
    document.getElementById("nameInput").focus()
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.nameLabel = storage.interface.nameLabel;
      this.emailLabel = storage.interface.emailLabel;
      this.phoneLabel = storage.interface.phoneLabel;
      this.consent1Text = '* ' + storage.interface.consent1Text;
      this.pageTitle = storage.pages.form.h1;
      this.buttonText = storage.pages.form.buttonText;
      this.fillFormText = storage.pages.form.fillFormText;
      this.mandatoryFieldText = storage.pages.form.mandatoryFieldText;
      this.mandatoryFieldsText = storage.pages.form.mandatoryFieldsText;
      this.mandatoryConsentText = storage.pages.form.mandatoryConsentText;
      this.gameDuration = Math.floor((this.store.gameDuration/1000));
      this.playersAnswers = this.store.playersAnswers;
      this.playersScores = this.store.playersScores;
      this.playersResults = this.store.playersResults;
      this.players = this.store.players;
      this.gardenId = storage.app.gardenId;
      this.language = this.store.language??'';
    },
    submit : function() {
      this.validate();
      if (this.valid) {
        this.submitted = true;
        store.formHasBeenSent = true;
        this.saveGame();
        this.$router.push({ name: 'gift-page'});
      }
    },
    saveGame() {
      http.post(`api/games/save`,
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            duration: this.gameDuration,
            answers: this.playersAnswers,
            scores: this.playersScores,
            results: this.playersResults,
            players: this.players,
            gardenId: this.gardenId,
            language: this.language,
          })
          .then(() => {})
          .catch((e) => {
            console.log(e);
          })
    },
    validate : function(){
      this.valid = true;
      this.nameBlured = true;
      this.emailBlured = true;
      this.phoneBlured = true;
      this.consent1Blured = true;
      if(!this.validName(this.name)){
        this.valid = false;
      }
      if(!this.validEmail(this.email)){
        this.valid = false;
      }
      if(!this.validPhone(this.phone)){
        this.valid = false;
      }
      if(!this.validPhone(this.phone)){
        this.valid = false;
      }
      if(!this.validConsent1()){
        this.valid = false;
      }
    },
    validName : function(name) {
      var re = /^.{3,100}$/;
      return re.test(name.toLowerCase());
    },
    validEmail : function(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validPhone : function(phone) {
      if(phone.length==0) return true;
      var re = /^[0-9 \-+()]{9,300}$/;
      return re.test(phone.toLowerCase());
    },
    validConsent1 : function(){
      return this.consent1;
    },
  },
}
</script>

<style scoped>
#game{
  width: 80%;
  padding: 30px 0px;
}
h1{
  font-size: 15pt;
  margin-bottom: 30px;
}
.fillFormText, label{
  font-size: 11pt;
  line-height: 21pt;
  text-align: left;
}
label.consent, .mandatoryFieldsText{
  font-size: 9pt;
  line-height: 13pt;
  text-align: left;
}
#giftButton{
  margin-top: 30px;
}
hr{
  margin: 10px 0px;
}
.form-group, .form-check{
  margin-top: 15px;
}
.invalid-feedback{
  font-size: 9pt;
  line-height: 13pt;
  text-align: left;
}
</style>
