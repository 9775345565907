import axios from 'axios'
import {handleError} from '@/utils/HttpErrorHandler'

const http = axios;
http.defaults.baseURL = '/';//process.env.VUE_APP_API_URI;
http.interceptors.response.use(
  (r) => {
    return Promise.resolve(r)
  },
  (e) => {
    handleError(e);
    return Promise.reject(e)
  }
);

export default http;
