<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center" id="game">
      <h1>{{pageTitle}}</h1>
      <div class="btn-group" role="group" >
        <input type="radio" class="btn-check" name="choosePlayer" id="player1" autocomplete="off" v-if='store.players[0]' @click="changePlayer(1);" checked>
        <label class="btn btn-outline-primary d-flex justify-content-center align-items-center btn-player" for="player1" v-if='store.players[0]'>{{store.players[0]}}</label>

        <input type="radio" class="btn-check" name="choosePlayer" id="player2" autocomplete="off" v-if='store.players[1]' @click="changePlayer(2);">
        <label class="btn btn-outline-primary d-flex justify-content-center align-items-center btn-player" for="player2" v-if='store.players[1]'>{{store.players[1]}}</label>

        <input type="radio" class="btn-check" name="choosePlayer" id="player3" autocomplete="off" v-if='store.players[2]' @click="changePlayer(3);">
        <label class="btn btn-outline-primary d-flex justify-content-center align-items-center btn-player" for="player3" v-if='store.players[2]'>{{store.players[2]}}</label>

        <input type="radio" class="btn-check" name="choosePlayer" id="player4" autocomplete="off" v-if='store.players[3]' @click="changePlayer(4);">
        <label class="btn btn-outline-primary d-flex justify-content-center align-items-center btn-player" for="player4" v-if='store.players[3]'>{{store.players[3]}}</label>
      </div>
      <game-table player="1" v-if="activePlayer==1"></game-table>
      <game-table player="2" v-if="activePlayer==2"></game-table>
      <game-table player="3" v-if="activePlayer==3"></game-table>
      <game-table player="4" v-if="activePlayer==4"></game-table>
      <div class="buttons-line"><button type="button" class="btn btn-primary" id="resultsButton" @click="endGame()" >{{buttonText}}</button></div>
    </div>

    <div class="modal" id="errorModal" aria-hidden="false" :class="{ show: errorModalActive, 'd-block': errorModalActive }">
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-body">
            <p>{{errorText}}</p>
          </div>
          <div class="col-12 text-center">
            <button type="button" class="btn btn-dark" @click="errorModalActive=false">{{okButtonText}}</button>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import GameTable from "@/components/game/Table";
import store from "../../store/store";

export default {
  components: {HeaderBar,GameTable},
  name: "GamePage",
  data() {
    return {
      store: store,
      activePlayer: 1,
      pageTitle: '',
      buttonText: '',
      errorText: '',
      okButtonText: '',
      errorModalActive: false,
    }
  },
  mounted() {
    this.getVariables();
    this.checkPlayers();
    store.phoneButton = true;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = true;
    store.logoSmall = true;

  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.okButtonText = storage.interface.okButtonText;
      this.pageTitle = storage.pages.game.h1;
      this.buttonText = storage.pages.game.buttonText;
      this.errorText = storage.pages.game.errorText;
      console.log("Data rozpoczęcia gry: " + this.store.gameStartTime);
    },
    checkPlayers(){
      let anyPlayer = false;
      store.players.forEach((element) => {
        if(element!='') anyPlayer = true;
      });
      if(!anyPlayer) this.$router.push('/');
    },
    changePlayer(activePlayer){
      this.activePlayer = activePlayer;
    },
    endGame(){
      if(this.haveAllAnswers()){
        if(this.store.gameDuration==0){
          let ms = Date.now() - this.store.gameStartTime;
          this.store.gameDuration = ms;
        }
        this.$router.push('results');
      }else{
        this.errorModalActive = true;
      }

    },
    haveAllAnswers(){
      let haveAllAnswers = true;
      store.players.forEach((element,index) => {
        if(element!=''){
          for (let step = 1; step <= 10 ; step++) {
            if(!this.store.playersAnswers[index+1][step]) haveAllAnswers = false;
          }
        }
      });
      return haveAllAnswers;
    }
  },
}
</script>

<style scoped>
#game{
  width: 80%;
  padding: 20px 0px;
}
h1{
  font-size: 15pt;
  margin: 0px;
}
.btn-outline-primary, .btn-outline-primary:visited, .btn-outline-primary:hover, .btn-outline-primary:active{
  font-size: 8pt;
  color: #b7b7b7 !important;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  text-transform: none;
}
.btn-check:checked+.btn-outline-primary{
  font-size: 11pt;
  color: #ffffff !important;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
}
.btn-group{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.btn-player{
  padding: 0px 5px;
  width: auto !important;
}
</style>
