<template>
  <div class="header-small w-100">
    <div class="row" id="lang-line">
      <div class="col back-button">
        <span v-if="storeLocal.showBackButton" class="carousel-control-prev-icon float-start" aria-hidden="true"></span>
        <div v-if="storeLocal.showBackButton" class="float-start back-button-link" @click="$router.go(-1)">{{
            backButtonText
          }}
        </div>
      </div>
      <div class="col">
      </div>
      <div class="col">
        <div class="dropdown" v-if="language">
          <div id="lang-widget" class="float-end dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img :src="require('@/assets/images/lang-'+getAsset(language)+'.png')" id="lang-icon">
            <span id="current-lang-text">{{ getLangName(language) }}</span>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" id="lang-dropdown" on>
            <li v-for="lang in availableLanguages" :key="lang"><a class="dropdown-item" href="#" @click="changelanguage(lang)"><img
                :src="require('@/assets/images/lang-'+lang+'.png')" id="lang-icon"> {{ getLangName(lang) }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/store.js'

export default {
  name: "LangIcons",
  data() {
    return {
      storeLocal: store,
      language: '',
      availableLanguages: [],
      languagesConfig: [],
      backButtonText: '',
    }
  },
  mounted() {
    this.getVariables();
  },
  methods: {
    changelanguage(language) {
      this.storeLocal.language = language.toLowerCase();
    },
    getLangName(language) {
      if (!language) {
        return '';
      }
      if (this.languagesConfig[language] && this.languagesConfig[language].name) {
        return this.languagesConfig[language].name
      }
      return language.toUpperCase();
    },
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.backButtonText = storage.interface.backButtonText;
    },
    getAsset(language) {
      return language

    },
  },watch: {
    'store.availableLanguages': {
      handler: function() {
        this.availableLanguages = store.availableLanguages;
        this.languagesConfig = store.languagesConfig;
        this.language = store.language
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>
#lang-line {
  line-height: 13px;
  margin: 20px 20px 0px 20px;
}

#lang-widget {
}

#lang-icon {
  height: 13px;
  margin-right: 3px;
}

#current-lang-text {
  color: #ffffff;
  font-size: 13px;
}

.back-button {
  font-size: 10pt;
  line-height: 15pt;
}

.carousel-control-prev-icon {
  height: 19px;
}

.back-button-link {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  min-width: unset;
}

.dropdown-item {
  padding: 4px;
}

li a:link, li a:hover, li a:visited, li a:active {
  color: #000000;
  text-decoration: none;
}
</style>
