import "bootstrap/dist/css/bootstrap.min.css"
import './assets/css/ogrodswiatel.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import VueGtag from "vue-gtag";

const app = createApp(App)

app.use(VueGtag, {
    config: { id: "UA-211179412-1" }
});

app.use(router)
router.isReady().then(() => {
    app.mount('#app')
})

import "bootstrap/dist/js/bootstrap.js"

