<template>
  <div class="w-100 d-flex flex-column">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center">
      <h1>{{pageTitle}}</h1>
      <div class="block-element phone-number"><a href="tel:{{phoneNumber}}">{{phoneNumber}}</a></div>
      <div class="block-element phone-text">{{phoneText}}</div>
      <hr class="block-element" />
      <div class="block-element email-text">{{emailText}}</div>
      <div class="block-element email"><a :href="`mailto:${email}`">{{email}}</a></div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from "../../store/store";

export default {
  components: {HeaderBar},
  name: "ContactPage",
  data() {
    return {
      store: store,
      pageTitle: '',
      phoneNumber: '',
      phoneText: '',
      emailText: '',
      email: '',
    }
  },
  mounted() {
    this.getVariables();
    store.phoneButton = false;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = true;
    store.logoSmall = true;
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.pageTitle = storage.pages.contact.h1;
      this.phoneNumber = storage.pages.contact.phoneNumber;
      this.phoneText = storage.pages.contact.phoneText;
      this.emailText = storage.pages.contact.emailText;
      this.email = storage.pages.contact.email;
    },
  },
}
</script>

<style>
h1{
  margin-bottom: 60px;
}
.phone-number{
  margin-bottom: 20px;
}
.phone-number a:link, .phone-number a:hover, .phone-number a:active, .phone-number a:visited{
  font-family: brother-1816, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24pt;
}
.phone-text{
  font-size: 9pt;
  line-height: 11pt;
  margin-bottom: 30px;
}
.email-text{
  margin-top: 30px
}
.email a{
  display: block;
  margin-top: 20px;
}
hr{
  height: 0px;
  border: none;
  border-top: 1px solid #ffffff;
  opacity: 1;
}
</style>
