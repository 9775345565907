<template>
  <table>
    <tr>
      <td></td>
      <td></td>
      <td class="answears-head"><button type="button" class="btn btn-info">a</button></td>
      <td class="answears-head"><button type="button" class="btn btn-info">b</button></td>
      <td class="answears-head"><button type="button" class="btn btn-info">c</button></td>
    </tr>
    <game-row question="1" :player="player"></game-row>
    <game-row question="2" :player="player"></game-row>
    <game-row question="3" :player="player"></game-row>
    <game-row question="4" :player="player"></game-row>
    <game-row question="5" :player="player"></game-row>
    <game-row question="6" :player="player"></game-row>
    <game-row question="7" :player="player"></game-row>
    <game-row question="8" :player="player"></game-row>
    <game-row question="9" :player="player"></game-row>
    <game-row question="10" :player="player"></game-row>
  </table>
</template>

<script>
import store from '../../store/store.js'
import GameRow from "@/components/game/Row";

export default {
  components: {GameRow},
  name: "GameTable",
  props: ['player'],
  data() {
    return {
      store: store,
    }
  },
  mounted() {
    this.getVariables();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      document.getElementById("footer").style.backgroundImage =   "url('" + storage.footer.bgimage + "')";
    },
  },
}
</script>

<style scoped>
table{
  margin: 10px auto;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.answears-head{
  width: 33px;
  height: 25px;
}
.answears-head button{
  width: 33px;
  height: 25px;
  padding: 0px;
}
</style>
