<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center">
      <h1>{{pageTitle}}</h1>

      <div class="input-group text-block" v-if="store.player1InputVisible">
        <input type="text" class="form-control" :placeholder="placeholderPlayerName" v-model="store.players[0]" @input="changeName(1, $event.target.value);" maxlength="15">
        <span class="input-group-text" v-if='store.players[0]' @click="playerToDelete=1" data-bs-target="#removePlayerModal" data-bs-toggle="modal"><img :src="require('@/assets/images/bin.png')" height="14"></span>
      </div>
      <div class="input-group text-block" v-if="store.player2InputVisible">
        <input type="text" class="form-control" :placeholder="placeholderPlayerName"  v-model="store.players[1]" @input="changeName(2, $event.target.value);" maxlength="15">
        <span class="input-group-text " @click="playerToDelete=2" data-bs-target="#removePlayerModal" data-bs-toggle="modal"><img :src="require('@/assets/images/bin.png')" height="14"></span>
      </div>
      <div class="input-group text-block" v-if="store.player3InputVisible">
        <input type="text" class="form-control" :placeholder="placeholderPlayerName"  v-model="store.players[2]" @input="changeName(3, $event.target.value);" maxlength="15">
        <span class="input-group-text" @click="playerToDelete=3" data-bs-target="#removePlayerModal" data-bs-toggle="modal"><img :src="require('@/assets/images/bin.png')" height="14"></span>
      </div>
      <div class="input-group text-block" v-if="store.player4InputVisible">
        <input type="text" class="form-control" :placeholder="placeholderPlayerName"  v-model="store.players[3]" @input="changeName(4, $event.target.value);" maxlength="15">
        <span class="input-group-text" @click="playerToDelete=4" data-bs-target="#removePlayerModal" data-bs-toggle="modal"><img :src="require('@/assets/images/bin.png')" height="14"></span>
      </div>

      <div class="buttons-line"><button type="button" class="btn btn-light" id="addNewPlayerButton" disabled @click="addNewPlayer"  v-show="!store.player2InputVisible || !store.player3InputVisible || !store.player4InputVisible">{{addPlayerButtonText}}</button></div>
      <div class="buttons-line"><button type="button" class="btn btn-primary" id="startButton" disabled @click="startGame();">{{startButtonCurrentText}}</button></div>
      <div class="buttons-line"><button type="button" class="btn btn-secondary" id="continueButton" disabled @click="continueGame();" v-if="(this.store.gameDuration==0 && this.store.gameStartTime)">{{continueButtonText}}</button></div>
      <img :src="require('@/assets/images/players.png')" class="players-icon mx-auto" v-if="viewImage">
      <div class="text-block" v-html="text"></div>

      <div class="modal" id="removePlayerModal">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body">
              <p>{{deleteConfirmText}}</p>
            </div>
            <div class="col-12 text-center">
              <button type="button" class="btn btn-dark" data-bs-dismiss="modal">{{noButtonText}}</button>
              <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="deletePlayer">{{yesButtonText}}</button>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from "../../store/store";

export default {
  components: {HeaderBar},
  name: "PlayersPage",
  data() {
    return {
      store: store,
      viewImage: true,
      pageTitle: '',
      text: '',
      deleteConfirmText: '',
      noButtonText: '',
      yesButtonText: '',
      startButtonCurrentText: '',
      startButtonText: '',
      newGameButtonText: '',
      addPlayerButtonText: '',
      continueButtonText: '',
      placeholderPlayerName: '',
      playerToDelete: '',
    }
  },
  mounted() {
    this.getVariables();
    store.phoneButton = true;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = false;
    store.logoSmall = false;
    this.startButtonCurrentText = this.startButtonText;
    this.setButtons();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.pageTitle = storage.pages.players.h1;
      this.text = storage.pages.players.text;
      this.deleteConfirmText = storage.pages.players.deleteConfirmText;
      this.noButtonText = storage.interface.noButtonText;
      this.yesButtonText = storage.interface.yesButtonText;
      this.startButtonText = storage.pages.players.startButtonText;
      this.newGameButtonText = storage.pages.players.newGameButtonText;
      this.addPlayerButtonText = storage.pages.players.addPlayerButtonText;
      this.continueButtonText = storage.pages.players.continueButtonText;
      this.placeholderPlayerName = storage.pages.players.placeholderPlayerName;
    },
    addNewPlayer() {
      if(store.player1InputVisible==false) store.player1InputVisible = true;
      else if(store.player2InputVisible==false) store.player2InputVisible = true;
      else if(store.player3InputVisible==false) store.player3InputVisible = true;
      else if(store.player4InputVisible==false) store.player4InputVisible = true;
      this.setButtons();
    },
    changeName(player, val){
      store.players[player-1] = val;
      this.setButtons();
    },
    deletePlayer(){
      if(this.playerToDelete==1) {store.players[0]=''; store.player1InputVisible=false;}
      if(this.playerToDelete==2) {store.players[1]=''; store.player2InputVisible=false;}
      if(this.playerToDelete==3) {store.players[2]=''; store.player3InputVisible=false;}
      if(this.playerToDelete==4) {store.players[3]=''; store.player4InputVisible=false;}

      this.checkWhatIsVisible();
      this.setButtons();
    },
    checkWhatIsVisible() {
      if(store.player1InputVisible==false && store.player2InputVisible==false && store.player3InputVisible==false && store.player4InputVisible==false){
        store.player1InputVisible = true;
      }
    },
    setButtons() {
      let activate = true;
      if(store.player1InputVisible==true && store.players[0].length==0) activate = false;
      if(store.player2InputVisible==true && store.players[1].length==0) activate = false;
      if(store.player3InputVisible==true && store.players[2].length==0) activate = false;
      if(store.player4InputVisible==true && store.players[3].length==0) activate = false;

      if(activate){
        document.getElementById('addNewPlayerButton').removeAttribute('disabled');
        document.getElementById('startButton').removeAttribute('disabled');
        if(document.getElementById('continueButton'))  document.getElementById('continueButton').removeAttribute('disabled');
        if(this.store.gameStartTime){
          this.startButtonCurrentText = this.newGameButtonText;
        }
      }
      else{
        document.getElementById('startButton').setAttribute('disabled', '');
        document.getElementById('addNewPlayerButton').setAttribute('disabled', '');
        if(document.getElementById('continueButton'))  document.getElementById('continueButton').setAttribute('disabled', '');
      }
    },
    startGame(){
      this.store.gameDuration = 0;
      this.store.gameStartTime = Date.now();
      this.store.playersAnswers = {
        "1": {},
        "2": {},
        "3": {},
        "4": {}
      };
      this.$router.push('game');
    },
    continueGame(){
      this.$router.push('game');
    }
  },
}
</script>

<style scoped>
h1{
  margin: 20px auto;
}
.buttons-line{
  margin-top: 10px;
}
.input-group-text{
  background-color: #e7c67b ;
  border-color: #e7c67b ;
  font-family: brother-1816, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12pt;
}
.players-icon{
  width: 220px;
}
.text-block{
  font-size: 9pt;
  line-height: 11pt;
  margin: 10px auto 0px auto;
}
</style>
