<template>
  <div class="w-100 d-flex flex-column">
    <lang-icons></lang-icons>
    <div class="h-100 flex-column d-flex justify-content-center">
      <img :src="map" class="map">
    </div>
  </div>
</template>

<script>
import LangIcons from "@/components/lang/Icons";
import store from '../../store/store.js'

export default {
  components: {LangIcons},
  name: "MapPage",
  data() {
    return {
      store: store,
      map: '',
    }
  },
  mounted() {
    store.phoneButton = true;
    store.mapButton = false;
    store.infoButton = true;
    store.showBackButton = true;
    store.logoSmall = false;
    this.getVariables();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.map = storage.pages.map.image;
    },
  },
}
</script>

<style scoped>
.map{
  padding: 40px 0px;
  max-width: 80%;
  margin: 0px auto;
}
</style>
