<template>
  <footer class="footer d-flex justify-content-center" id="footer">
    <div class="footer-inner">
      <img v-if="store.phoneButton" :src="phone" class="phone float-start" id="phoneButton" @click="$router.push('contact')">
      <img v-if="store.mapButton" :src="map" class="maps float-end" id="mapButton" @click="$router.push('map')">
      <img v-if="store.infoButton" :src="info" class="maps float-end" id="infoButton" @click="$router.push('info')">
    </div>
  </footer>
</template>

<script>
import store from '../../store/store.js'

  export default {
    name: "FooterBar",
    data() {
      return {
        store: store,
        phone: '',
        map: '',
        info: '',
      }
    },
    mounted() {
      this.getVariables();
    },
    methods: {
      getVariables() {
        let storage = JSON.parse(localStorage.getItem('ogrod'));
        this.phone = storage.interface.phone;
        this.map = storage.interface.map;
        this.info = storage.interface.info;
        let footer = document.getElementById("footer");
        if (storage) {
          footer.style.backgroundImage =   "url('" + storage.footer.bgimage + "')";
        }
      },
    },
  }
</script>

<style scoped>
  .footer {
    margin-left: 0 !important;
  }
  .phone, .maps{
    height: 40px;
    width: auto;
    margin: 0px 70px;
  }
</style>
