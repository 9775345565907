<template>
  <div class="header-large w-100">
    <div class="">
      <div class="">
        <lang-icons></lang-icons>
        <div class="">
          <img v-if="store.logoSmall" :src="logo" id="logo-small" @click="$router.push('players')">
          <img v-else :src="logo" id="logo-large" @click="$router.push('players')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/store.js'
import LangIcons from "@/components/lang/Icons";

export default {
  name: "HeaderBar",
  components: {LangIcons},
  data() {
    return {
      store: store,
      title: '',
      logo: '',
    }
  },
  mounted() {
    this.getVariables();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      if (storage) {
        this.logo = storage.header.logo;
      }
    }
  },
}
</script>

<style scoped>
.lang-bar{
  height: 45px;
  width: 100%;
  border: 1px solid white;
}
#logo-large{
  margin-top: 20px;
  height: 90px;
}
#logo-small{
  margin-top: -13px;
  height: 55px;
}
</style>
