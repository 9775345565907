<template>
  <div class="w-100 d-flex flex-column">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center">
      <h1>{{pageTitle}}</h1>
      <img :src="map" class="gift-map">
      <div class="text-block">{{text}}</div>
      <div class="buttons-line">
        <button type="button" class="btn btn-primary" @click="$router.push('results')">{{buttonText}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from '../../store/store.js'

export default {
  components: {HeaderBar},
  name: "GiftPage",
  data() {
    return {
      store: store,
      map: '',
      pageTitle: '',
      text: '',
      buttonText: '',
    }
  },
  mounted() {
    store.phoneButton = false;
    store.mapButton = false;
    store.infoButton = false;
    store.showBackButton = false;
    store.logoSmall = true;
    this.getVariables();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.map = storage.pages.gift.image;
      this.pageTitle = storage.pages.gift.h1;
      this.text = storage.pages.gift.text;
      this.buttonText = storage.pages.gift.buttonText;
    },
  },
}
</script>

<style scoped>
h1{
  margin: 20px auto;
}
.gift-map{
  padding: 0px 0px;
  max-width: 80%;
  margin: 0px auto;
}
.text-block{
  font-size: 9pt;
  line-height: 11pt;
  margin: 20px auto 30px auto;
}
.buttons-line{
  display: block;
  margin: 0px auto 0px auto;
  text-align: center;
}
</style>
