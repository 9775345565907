<template>
  <div id="app-container" class="d-flex flex-column">
    <div v-if="!isLoading" class="page-container d-flex justify-content-center flex-grow-1">
      <div class="page-inner-container d-flex justify-content-center">
        <router-view></router-view>
      </div>
    </div>
    <footer-bar v-if="!isLoading"></footer-bar>
  </div>
</template>

<script>
import http from "@/axios";
import FooterBar from "@/components/footer/Bar"
import store from "./store/store.js";

export default {
  components: {FooterBar},
  props: [''],

  computed: {
    language() {
      return this.store.language;
    }
  },
  data() {
    return {
      store: store,
      isLoading: true,
      linkcColor: '',
      settings: {
        enabled: true,
        title: '',
        garden: '',
      }
    }
  },
  watch: {
    language(newVal, oldVal) {
      if (newVal !== oldVal) {
        window.location.reload();
      }
    }
  },
  created() {
    this.fetchSettings();
  },
  mounted() {
    this.setAppHeight();
    window.addEventListener('resize', this.setAppHeight);
  },
  methods: {
    setAppHeight() {
      document.getElementById('app-container').style.minHeight = window.innerHeight + "px";
    },
    fetchSettings() {
      let defaultLang = '';
      let lang = store.language??defaultLang;
      http.post(`api/settings`, {ogrod: this.$route.params.ogrod, language: lang })
          .then((r) => {
            if (typeof r.data.redirect !== 'undefined' && r.data.redirect.length > 0) {
              window.location.href = r.data.redirect;
              return;
            }
            this.settings = r.data.settings;
            localStorage.setItem('ogrod', JSON.stringify(r.data.settings));
            document.title = this.settings.title;
            this.isLoading = false;
            document.body.style.backgroundColor = this.settings.app.color;
            this.linkcColor = this.settings.app.linksColor;
            store.language = this.settings.lang.current;
            store.availableLanguages = this.settings.lang.availableLanguages;
            store.languagesConfig = this.settings.lang.languages;
          })
          .catch((e) => {
            console.log(e);
          })
    }
  },
}
</script>

<style>
a:link, a:hover, a:visited, a:active {
  color: v-bind(linkcColor);

}

a:link, a:active, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
